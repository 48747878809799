.table-ae {
  @apply table;
}

.table-ae thead {
  @apply top-0 z-20 border-b border-black/10;
}

.table-ae thead th {
  @apply ae-typography-body2  bg-transparent normal-case text-black/40;
}

.table-ae tr td {
  @apply bg-transparent;
}

.table-ae th:first-child {
  position: static;
}

.table-ae:where(:not(.table-zebra))
  :where(thead, tbody, tfoot)
  :where(tr:not(:last-child) :where(th, td)) {
  @apply border-black/10;
}

.table-ae tr.hover:hover th,
.table-ae tr.hover:hover td,
.table-ae tr.hover:nth-child(even):hover th,
.table-ae tr.hover:nth-child(even):hover td {
  @apply bg-black/5;
}

.table-ae tr.disabled td {
  @apply opacity-40;
}

.table-ae tr.expanded td {
  @apply border-none bg-black/5;
}
