.tooltip::before {
  @apply bg-base-content p-4;
}

.tooltip-rounded::before {
  @apply rounded-lg;
}

.tooltip-light::before {
  @apply bg-white text-black/60 shadow-lg;
}

.tooltip.ae-tooltip:before {
  @apply py-3;
  @apply ae-typography-body2  text-white/70;
  @apply font-normal;
}
