.react-calendar {
  @apply rounded-md border-[1px] border-black/10 p-2;
}

/* CALENDAR TOP NAV */
.react-calendar__navigation {
  @apply relative mb-4 grid grid-cols-4 place-content-between;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__label {
  @apply ae-typography-body2 pointer-events-none order-1 col-span-2 cursor-default text-start;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  @apply btn-ghost btn-sm btn-circle btn absolute right-10 top-0 order-2 h-5 min-h-0 w-5 min-w-0 p-0 text-black/40;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  @apply btn-ghost btn-sm btn-circle btn absolute right-2 top-0 order-3 h-5 min-h-0 w-5 min-w-0 p-0 text-black/40;
}

/* CALENDAR WEEK NAV */
.react-calendar__month-view__weekdays {
  @apply ae-typography-detail3 mb-2 text-center text-black/40;
}

.react-calendar__month-view__weekdays__weekday {
  @apply gap-x-2;
}

.react-calendar__month-view__weekdays__weekday abbr {
  @apply uppercase no-underline;
}

/* CALENDAR GRID */
.react-calendar__month-view__days {
  @apply grid grid-cols-7;
}

/* CALENDAR GRID ITEMS */
.react-calendar__tile {
  @apply p-1 text-base-content/60;
}

.react-calendar__tile abbr {
  @apply ae-typography-body2 flex h-8 w-full items-center justify-center rounded-[4px];
}

.react-calendar__tile--now abbr {
  @apply relative;
}

.react-calendar__tile--now abbr::after {
  content: ".";
  @apply absolute top-3 text-[20px];
}

.react-calendar__tile--active abbr {
  @apply bg-black text-white;
  @apply transition-colors duration-300;
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  @apply text-base-content/40;
}

.react-calendar__tile--past,
.react-calendar__tile--not-available {
  @apply pointer-events-none cursor-default;
}

.react-calendar__month-view__days__day.react-calendar__tile.react-calendar__tile--past
  abbr,
.react-calendar__month-view__days__day.react-calendar__tile.react-calendar__tile--not-available
  abbr {
  @apply line-through;
}

.react-calendar__tile:not(.react-calendar__tile--active) abbr {
  @apply ae-typography-body2 btn-ghost btn h-8 min-h-0 w-full min-w-0 rounded-[4px] p-1;
}
